import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import data from "../../Content/about-us-page-content.json"
import "./pages.scss"
import BackArrow from "../images/icons/arrow-left.png"
import SmQuotesGroupIcon from '../images/icons/logo-mo.png';
import UpArrow from "../images/icons/up-arrow.png";
import SEO from "../components/seo";
import PWMarkdown from '../components/common/PWMarkdown';

const EarlyYearImg = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-story-eocl-de.jpg";
const MostPeopleImg = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-story-hmpl-de.png";
const BringingExpertImg = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-story-bi-de.png";
const TailoringTipsImg = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-story-to-de.png";
const EarlyYearImgSm = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-story-eocl-mo.jpg";

const Logo = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png";

const Subtitle = (props) => {
    const { data } = props;
    return (
        <div className="w3-content ab-us-os-title-sub">
            {data}
        </div>
    )
}

const OurStory = ({ location }) => {

    return (
        <div className="ab-us-os-background">
            <div className="w3-hide-large w3-hide-medium" style={{ position: "relative" }}>
                <img className="about-us-os-logo" src={SmQuotesGroupIcon} />
            </div>
            <Layout>
                <SEO
                    title="Our Story"
                    path={location.pathname}
                />
                <div className="w3-hide-large w3-hide-medium" style={{ display: "flex", position: "relative" }}>
                    <div className="super-ho-title">{data.header}</div>
                </div>
                <div className="w3-hide-large w3-hide-medium about-us-bor">
                    <Link to="/about-us" className="about-us-button">Why Parentwise</Link>
                    <span><img style={{ marginBottom: "5px" }} src={UpArrow} /></span>
                    <Link to="/join-the-movement" className="about-us-button">Join the Movement</Link>
                    <Link to="/our-story" style={{ marginLeft: "15px" }} className="about-us-button about-us-border">Our Story</Link>
                </div>
                <img loading="lazy" className="super-ho-logo w3-hide-small" src={Logo} />
                <div className="w3-row w3-hide-small" style={{ position: "relative" }}>
                    <Link to="/" className="pw-wt-detail-back-to" style={{ position: "absolute" }}>
                        <img src={BackArrow} className="pw-wt-detail-back-icon" />
                        <div>
                            Back to Homepage
                        </div>
                    </Link>
                </div>
                <div className="w3-container pw-our-story-page">
                    <div className="ab-us-os-header w3-center w3-hide-large w3-hide-medium">Our Story</div>
                    <div className="ab-us-os-header w3-center w3-hide-small">{data.our_story.header}</div>
                    <div className="w3-content">
                        <PWMarkdown data={data.our_story.para_1} />
                        <div className="w3-center ab-us-os-img w3-hide">
                            <img loading="lazy" className="os-image" src={EarlyYearImg} />
                        </div>
                        <div className="w3-center ab-us-os-img w3-hide">
                            <img loading="lazy" src={EarlyYearImgSm} style={{ width: "96%" }} />
                        </div>
                        <Subtitle data={data.our_story.sub_title_1} />
                        <PWMarkdown data={data.our_story.para_3} />
                        <div className="w3-center ab-us-os-img-top">
                            <img loading="lazy" className="os-image-1" src={MostPeopleImg} />
                        </div>
                        <PWMarkdown data={data.our_story.para_4} />
                        <Subtitle data={data.our_story.sub_title_2} />
                        <PWMarkdown data={data.our_story.para_5} />
                        <div className="w3-center ab-us-os-img">
                            <img loading="lazy" className="os-image-2" src={BringingExpertImg} />
                        </div>
                        <Subtitle data={data.our_story.sub_title_3} />
                        <PWMarkdown data={data.our_story.para_6} />
                        <div className="w3-center ab-us-os-img" style={{ paddingBottom: "15px" }}>
                            <img loading="lazy" className="os-image" src={TailoringTipsImg} />
                        </div>
                        <PWMarkdown data={data.our_story.para_7} />
                        <Subtitle data={data.our_story.sub_title_4} />
                        <div className="our-story-padding">
                            <PWMarkdown data={data.our_story.para_8} />
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default OurStory